import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CGeneralMedia,
  CSectTitle,
  CSectTitle03,
  CDefinition,
  LOgata,
  CSimpleAccordionList,
  CustomLink,
  CBtnList,
  CMenuMedia,
} from '../../../../components/_index';
import shopGet from '../../../../utils/shop-get';
import menuChoice from '../../../../utils/menu-choice';
import menuGet from '../../../../utils/menu-get';
import '../../../../assets/_sass/page/keikaen.scss';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('keikaen'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout isKeikaen={true}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="exLarge"
        data={{
          title: {
            en: 'RESTAURANT',
            ja: 'レストラン',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/keikaen/restaurants/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/keikaen/restaurants/kv__sp.png',
              },
            },
          ],
        }}
        exClass="c_jumbotron_keikaen"
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
              {
                label: '中国料理「桂花苑」',
                path: '/restaurants/keikaen/',
              },
            ],
            current: {
              label: frontmatter?.title.includes('-')
                ? frontmatter?.title.split('-')[1]
                : frontmatter?.title,
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CSectTitle03 type="en" title={{ sub: 'ランチ', main: 'LUNCH' }} />
          <CGeneralMedia
            data={menuChoice(menuGet(), ['桂花苑 LUNCH'], 1).map((item) => {
              return {
                img: {
                  src: item.node.eyecatch.url,
                  alt: '',
                },
                title: (
                  <>
                    {item.node.title.split('\n').map((t: string) => (
                      <>
                        {t}
                        <br />
                      </>
                    ))}
                  </>
                ),
                text: (
                  <>
                    <p>
                      {item.node.subtitle &&
                        item.node.subtitle.split('\n').map((t: string) => (
                          <>
                            {t}
                            <br />
                          </>
                        ))}
                    </p>
                    <ul className="linkList">
                      {item.node.menu_pdf.map((link: any, index02: number) => {
                        return (
                          <li key={index02}>
                            <CustomLink
                              to={link.pdf.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link.menu_title}
                              <i className="icon-document"></i>
                            </CustomLink>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              };
            })}
            exClass="u_mbExLarge u_mtLarge"
          />
          <CSectTitle03 type="en" title={{ sub: 'ディナー', main: 'DINNER' }} />
          <CGeneralMedia
            reverse={true}
            data={menuChoice(menuGet(), ['桂花苑 DINNER'], 1).map((item) => {
              return {
                img: {
                  src: item.node.eyecatch.url,
                  alt: '',
                },
                title: (
                  <>
                    {item.node.title.split('\n').map((t: string) => (
                      <>
                        {t}
                        <br />
                      </>
                    ))}
                  </>
                ),
                text: (
                  <>
                    <p>
                      {item.node.subtitle &&
                        item.node.subtitle.split('\n').map((t: string) => (
                          <>
                            {t}
                            <br />
                          </>
                        ))}
                    </p>
                    <ul className="linkList">
                      {item.node.menu_pdf.map((link: any, index02: number) => {
                        return (
                          <li key={index02}>
                            <CustomLink
                              to={link.pdf.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link.menu_title}
                              <i className="icon-document"></i>
                            </CustomLink>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              };
            })}
            exClass="u_mbExLarge u_mtLarge"
          />
          <CSectTitle03
            type="en"
            title={{ sub: 'アニバーサリー', main: 'ANNIVERSARY' }}
          />
          <CGeneralMedia
            data={menuChoice(menuGet(), ['桂花苑 ANNIVERSARY'], 1).map(
              (item) => {
                return {
                  img: {
                    src: item.node.eyecatch.url,
                    alt: '',
                  },
                  title: (
                    <>
                      {item.node.title.split('\n').map((t: string) => (
                        <>
                          {t}
                          <br />
                        </>
                      ))}
                    </>
                  ),
                  text: (
                    <>
                      <p>
                        {item.node.subtitle &&
                          item.node.subtitle.split('\n').map((t: string) => (
                            <>
                              {t}
                              <br />
                            </>
                          ))}
                      </p>
                      <ul className="linkList">
                        {item.node.menu_pdf.map(
                          (link: any, index02: number) => {
                            return (
                              <li key={index02}>
                                <CustomLink
                                  to={link.pdf.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {link.menu_title}
                                  <i className="icon-document"></i>
                                </CustomLink>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  ),
                };
              }
            )}
            exClass="u_mbLarge u_mtLarge"
          />
          <CBtnList
            data={[
              {
                label: 'ご予約',
                link: {
                  href: 'https://booking.ebica.jp/webrsv/search/e014024501/12253',
                  blank: true,
                },
                color: 'borderBlack',
              },
            ]}
            exClass="u_mbExLarge"
          />
          <div id="private-room-section">
          <CSectTitle03
            type="en"
            title={{ main: 'PRIVATE ROOM', sub: '個室' }}
            
          />
          </div>
          <h3 className="u_pc c_headingLv2">
            ゆっくりくつろげる
            <br />
            会話を愉しむ個室
          </h3>

          <CGeneralMedia
            exClass="c_generalMediaUnit_mb p_keikaen_private"
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/restaurants/img_restaurants04.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/restaurants/keikaen/restaurants/img_restaurants04__sp.png',
                  alt: '',
                },
                title: (
                  <>
                    ゆっくりくつろげる
                    <br />
                    会話を愉しむ個室
                  </>
                ),
                text: (
                  <>
                    <p>
                      雰囲気ある店内の隠れ家的な個室はご家族やご友人、大切なパートナーとの食事やお祝いに最適。美味しい料理、弾む会話。特別なひとときをごゆるりと。
                    </p>
                  </>
                ),
                definition: [
                  {
                    title: (
                      <>
                        <p className="u_fwb">個室</p>
                      </>
                    ),
                    text: (
                      <>
                        <p>2室</p>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <p className="u_fwb u_mt30">利用人数／室料</p>
                      </>
                    ),
                    text: (
                      <>
                        <p className="u_mb40">
                          4名～8名／8名～12名 ／ 6,000円～8,000円
                        </p>
                        <ul className="c_noteList">
                          <li>個室は大人4名様からご利用いただけます。</li>
                        </ul>
                      </>
                    ),
                  },
                ],
              },
            ]}
          />
          <CBtnList
            data={[
              {
                label: 'お問合せ',
                link: {
                  href: `https://contact.royalparkhotels.co.jp/srph/contact?_ifbs-srph_contact_form=s1_Step1`,
                  blank: true,
                },
                color: 'borderBlack',
                icon: 'arrrow',
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{ main: 'GUIDE', sub: '営業時間｜ご利用案内' }}
          />
          <CDefinition
            exClass="u_mb30"
            data={shopdata.information.map((item: any) => {
              return {
                title:
                  item.keyname &&
                  item.keyname.split('\n').map((t: string) => (
                    <>
                      {t}
                      <br />
                    </>
                  )),
                text: (
                  <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: `${item.value}`,
                    }}
                  />
                ),
              };
            })}
          />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
      </section>
      {/* <LOgata /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
